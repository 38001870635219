.info-container{
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  //border: 1px solid var(--color-primary);
  gap: 4rem;
  @media only screen and (max-width: $bp-small) {
    gap: 2rem;
    row-gap: 1rem;
  }
}

.personal-info {
  display: grid;
  grid-template-columns: 1fr  ;
  grid-template-rows: repeat(4, 1fr);
  row-gap: 2rem;
  flex: 1;
  @media only screen and (max-width: $bp-small) {
    flex: 1 0 100%;
    row-gap: 1rem;
  }
}

.form-container{
  display: grid;
  grid-template-columns: 1fr 1fr ;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  row-gap: 2rem;
  column-gap: 2rem;
  flex: 1;
  @media only screen and (max-width: $bp-small) {
    row-gap: 1rem;
  }
}

.personal-info .item{
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  background-color: var(--modal-outer-container);
  grid-column: 1 / span 2;

  &:nth-child(1){
    grid-row: 1/2;
  }
  &:nth-child(2){
    grid-row: 2/3;
  }
  &:nth-child(3){
    grid-row: 3/4;
  }
  &:nth-child(4){
    grid-row: 4/5;
  }
  &:nth-child(5){
    grid-row: 5/6;
  }
  &:nth-child(6){
    grid-row: 6/7;
  }
  &__icon{
    width: 2.5rem;
    height: 2.5rem;
    color: var(--color-primary);
    margin-right: 2rem;
    display: flex;
    flex: 0 0 auto;
  }
  &__phone:link,
  &__phone:visited{
    text-decoration: none;
    color: inherit;
  }
  @media only screen and (max-width: $bp-small) {
    padding: 1rem 2rem;
  }
}
// .form{
  
// }


.info{
  &__name,
  &__email,
  &__message{
    padding: 2rem;
    border: none;
    border-radius: var(--border-radius-default);
    background-color: var(--modal-outer-container);
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    width: 100%;
  }
  &__name{
    grid-column: 1 / 2;
    @media only screen and (max-width: $bp-medium) {
      grid-column: 1 / -1;
    }
  }
  &__email{
    grid-column: 2 / 3;
    @media only screen and (max-width: $bp-medium) {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    }
  }
  &__message{
    grid-row: 2 / span 2;
    grid-column: 1 / span 2;
    resize: none;
    @media only screen and (max-width: $bp-medium) {
      grid-row: 3 / span 2;
    }
  }
  &__button{
    grid-row: 4/5;
    background-color: var(--color-primary);
    padding: 1.2rem 0;
    font-family: inherit;
   
    @media only screen and (max-width: $bp-medium) {
      grid-row: 5/6;
    }
    @media only screen and (max-width: $bp-small) {
      grid-row: 5/6;
      grid-column: 1 / -1;
    }
  }
}

.form-container input::placeholder,
textarea::placeholder {
  color: var(--color-primary);
}

.form-container *:focus {
  outline: none;
}




 


/////////////////////////
/// CV DOWNLOAD
.cv {
  color: inherit;
  text-decoration: none;
  transition: all .3s;
  &__icon{
    fill: transparent;
    transition: all .6s;
  }

  &:hover &__icon{
    fill: var(--color-primary)
  }
  &:hover &__link{
    color: var(--color-primary);
  }
}
