.section-swiper{
  height: 25rem;  
  @media only screen and (max-width: $bp-medium) {
    height: 28rem; 
  }
  @media only screen and (max-width: $bp-small) {
    margin: 0 3rem;
    height: 33rem;
  }
  overflow-x: hidden;
}

.carousel-img{
  width: 100%;
  height: 200px;
  border-radius: var(--border-radius-default);
  // margin: 10rem auto;
  background-size: cover;
  cursor: pointer;
  background-position: center;
  @media only screen and (max-width: $bp-small) {
    //height: 254px;
    height: 220px;
    // background-size: contain;
    // background-repeat: no-repeat;
  }
  //pointer-events: all;
  // @media only screen and (max-width: $bp-small) {
  //   pointer-events: inherit;
  // }
}

.carousel-img-new{
  width: 100%;
  height: 200px;
  border-radius: var(--border-radius-default);
  // margin: 10rem auto;
  background-size: cover;
  cursor: pointer;
  background-position: center;
  @media only screen and (max-width: $bp-small) {
    //height: 254px;
    height: 220px;
    // background-size: contain;
    // background-repeat: no-repeat;
  }
  //pointer-events: all;
  // @media only screen and (max-width: $bp-small) {
  //   pointer-events: inherit;
  // }
  position: relative;
}

.carousel-img-new::after {
  content: 'new';
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  // background-color: var(--color-primary);
  // color: #333;
  background-color: var(--color-red);
  color: var(--color-primary);
  padding: 0.8rem 5.2rem;
  position: absolute;
  top: 5%;
  right: -14%;
  transform: rotate(45deg);
  @media only screen and (max-width: $bp-small) {
    top: 5%;
    right: -11%;
  }
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: var(--color-black);
  font-size: 1.7rem;
  border: 1px solid var(--color-black);
  opacity: 0;
  transition: all 0.5s;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-default);
}

.carousel-img:hover .carousel-caption{
  opacity: .8;
}


/********* container *******************/
.swiper {
  max-width: 400px !important;
  max-height: 300px !important;
  overflow: unset !important;
  overflow-x: clip !important;
  /*ultime agiunte*/
  display: flex;
  justify-content: flex-end;
}

 /********* arrows *******************/
.swiper-button-next,
.swiper-button-prev{
  z-index: 11 !important;
  top:auto !important;
  bottom: -37px;
  /* width: calc(var(--swiper-navigation-size)/ 4.4 * 31.2) !important; */
  width:unset;
  font-weight: 600;
  @media only screen and (max-width: $bp-small) {
    display: none;
  }
}
.swiper-slide{
  overflow-x: clip;
}
.swiper-button-prev{
  right: 50px !important;
  left: unset !important;
}
/* arrows size */
:root {
  --swiper-theme-color: var(--color-primary) !important;
  --swiper-navigation-size: 21px !important;
}

/********* bullets vertical alignment  *******************/
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: -35px !important
 }

 .swiper-pagination-bullet{
  background: var(--color-primary) !important
 }

 /* ULTIME AGGIUNTE */

 @media screen and (min-width: 600px) {
  .swiper {
    max-width: 600px !important;
    /* width: 640px !important; */
  }
 }
 @media screen and (min-width: 900px) {
  .swiper {
    max-width: 900px !important;
    /* width: 1000px !important; */
  }
 }


 .swiper-pagination {
  text-align: start !important;
  @media only screen and (max-width: $bp-small) {
   
    text-align: center !important;
  }
  }







