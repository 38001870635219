.heading-combo{
  display: flex;
  flex-wrap: wrap;
  align-items:center;
}
.heading-secondary{
  margin-right: auto;
  @media only screen and (max-width: $bp-small) {
    flex: 1 0 100%;
  }
}
.heading-secondary > span:first-child{
  margin-right: .5rem;
}
.counter{


  &__number{
    color: yellow;
    display: inline-block;
    font-size: 2.4rem;
    flex: 0 0 4.9rem;
    text-align: end;
    font-family: sans-serif;
    letter-spacing: 1.2px;
    font-weight: 300;
    margin-right: 1rem;
  }
  &__plus{
    
    color: red;
    align-self: flex-start;
    font-weight: 400;
    font-size: 2.2rem;
    flex: 0 0 1.5rem;
    text-align: end;
    font-family: sans-serif;
    letter-spacing: 1.2px;
  }
  &__default{
    font-weight: 500;
    margin-right: 0;
    font-size: 2.4rem;
    @media only screen and (max-width: $bp-small) {
      margin-right: auto;
    }
  }
}

//**************************
// INLINE ROTATOR
//*************************
.words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
  perspective: 300px;
}
.words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
  opacity: 0;
  transform-origin: 50% 100%;
  transform: rotateX(180deg);
}
.words-wrapper b.is-visible {
  opacity: 1;
  transform: rotateX(0deg);
  -webkit-animation: cd-rotate-1-in 1.2s;
  animation: cd-rotate-1-in 1.2s;
  position: relative;
}
.words-wrapper b.is-hidden {
  transform: rotateX(180deg);
  -webkit-animation: cd-rotate-1-out 1.2s;
  animation: cd-rotate-1-out 1.2s;
}
b, .initial{
  font-weight: inherit;
  color: var(--color-red);
}
@-webkit-keyframes cd-rotate-1-in {
  0% {
    -webkit-transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    -webkit-transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  to {
    -webkit-transform: rotateX(1turn);
    opacity: 1;
  }
}
@keyframes cd-rotate-1-in {
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  to {
    transform: rotateX(1turn);
    opacity: 1;
  }
}
@-webkit-keyframes cd-rotate-1-out {
  0% {
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    -webkit-transform: rotateX(-40deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  to {
    -webkit-transform: rotateX(180deg);
    opacity: 0;
  }
}
@keyframes cd-rotate-1-out {
  0% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    transform: rotateX(-40deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  to {
    transform: rotateX(180deg);
    opacity: 0;
  }
}