.main-container{
  max-width: 90rem;
  height: 100vh;
  margin: 0 auto;
  //position: relative;
  //background-color: var(--main-container);

  @media only screen and (max-width: $bp-small) {
    padding: 0 2rem;
    position: relative;
  }
  @media only screen and (max-width: $bp-medium) {
    max-width: 70rem;
  }
  background-color: transparent;
}
//////////////////////
/// MODAL
//////////////////////
.modal-container{
  width: 100%;
  height: 100vh;
  position: fixed;
  backface-visibility: hidden;
  z-index: 2;

  // WITHOUT SUPPORTS
  // -webkit-backdrop-filter: blur(3px);
  // backdrop-filter: blur(3px);
  // background-image: radial-gradient(circle at top left, rgba(255, 255, 255, 0.85), rgba(149, 61, 61, 0.85) 100%)

  // CHROME AND SAFARI
  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }

  // FIREFOX
  @supports not ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) { 
    background-image: radial-gradient(circle at top left, rgba(255, 255, 255, 0.85), rgba(149, 61, 61, 0.85) 100%);
  }
    
  
  @media only screen and (max-width: $bp-small) {
    background-color: var(--modal-outer-container);
    background-image: none;
  }
}

.modal-outer-container {
  display: flex;
  flex-direction: column;
  max-height: 85%;
  max-width: 80rem;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: var(--border-radius-default);

  background-color: var(--modal-outer-container);
  padding: 0 2rem;
  
  @media only screen and (max-width: $bp-small) {
    
    top: 5%;
    transform: translate(-50%, 0);
    padding-bottom: 2rem;
  }
}



