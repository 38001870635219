//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap');




:root{
  // COLORS
  --color-white:#fff;
  --color-black:#000;
  --color-red:red;
  --color-grey: #9e9e9e;

  
  --color-primary:yellow;
  
  --gradient-primary: linear-gradient(to right,var(--color-white), var(--color-primary));
  
  // FONT SIZE
  --default-font-size:1.6rem;

  // BORDER RADIUS
  --border-radius-default:1rem;
  
  /////////////////////////
  // BACKGROUNDS
  ///////////////////////
  /// alternative color body: #515964
  --body:#1e293b;
  //--body:#64748b;
  
  --main-container:#1e293b;
  //--main-container:rgb(100, 116, 139);

  //////////////////////
  // MODAL
  //--modal-container :rgba(0, 0, 0, .8);
  --modal-outer-container:#334155;
  --modal-inner-container:#334155;
  //--modal-inner-container:#a4a4a4;



}

// $bp-largest: 75em; // 1200px
// $bp-large: 68.75em; // 1100px
$bp-medium: 56.19em; // 900px
$bp-small: 37.44em; // 600px
// $bp-smallest: 31.25em; // 500px

$font-poppins:'Poppins', sans-serif;
$font-montserrat:'Montserrat', sans-serif;
$font-lato:'Lato', sans-serif;
$font-roboto:'Roboto', sans-serif;
$font-work-sans:'Work Sans', sans-serif;
$font-inter:'Inter', sans-serif;
$font-open-sans:'Open Sans', sans-serif;
$font-nunito:'Nunito', sans-serif;
$font-josefin-sans:'Josefin Sans', sans-serif;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px
  @media only screen and (max-width: $bp-medium) {
    font-size: 56.2%; // 9px/16px = 50%
  }
  @media only screen and (max-width: $bp-small) {
    font-size: 50%; // 8px/16px = 50%
  }
  overflow: hidden;
}


body {
  // font-family: $font-poppins;
  // font-family: $font-lato;
  // font-family: $font-roboto;
  // font-family: $font-work-sans;
  // font-family: $font-inter;
  // font-family: $font-open-sans;
  // font-family: $font-nunito;
  // font-family: $font-josefin-sans;
  font-family: $font-montserrat;


  font-weight: 400;
  line-height: 1.7;
  color: var(--color-white);
  font-size: var(--default-font-size); // 1.6 rem

  background-color: var(--body);
  
  overflow: hidden;
 // test
  background-color: #281e3b;
  background-image:
    radial-gradient(
      circle at top left,
      rgb(255 255 255 / 15%),
      #953d3d  100%
  
    ); 
    // #1e293b 100%

}


///////////////////////
/// TYPOGRAPHY
.heading-secondary {
  font-size: 3rem;
  letter-spacing: .8px;
  font-weight: 500;
  display: inline-block;
  color: var(--color-primary);
  text-transform: lowercase;
}

.heading-tertiary {
  font-size: var(--default-font-size);
  font-weight: 500;
  text-transform: uppercase;
}

.paragraph {
  font-size: var(--default-font-size);
}

///////////////////////
/// UTILITIES

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem;
}
.u-margin-bottom-big {
  margin-bottom: 8rem;
}