.form {
  &__group {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: $bp-small) {
      margin-top: 3rem;
    }
  }
  &__radio-group:not(:last-child) {
    margin-right: 5rem;
  }
  &__radio-input {
    display: none;
  }
  &__radio-label {
    font-size: 1.6rem;
    font-weight: 400;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
  &__radio-button {
    height: 2.2rem;
    width: 2.2rem;
    border: 4px solid var(--color-primary);
    border-radius: 50%;
    margin-right: 1rem;
    position: relative;

    &::after {
      content: '';
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background-color: var(--color-primary);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
  &__radio-input:checked ~ &__radio-label &__radio-button::after {
    opacity: 1;
  }
}