.profile{
  padding-top: 6rem;
  &__shape{
    width: 16.5rem;
    height: 16.5rem;
    -webkit-shape-outside: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%); 
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    float: left;
  }
  &__img{
    width: 100%;
    height: 100%;
  }
  &__text{
    transform: translateX(2rem);
    margin-right: 2rem;
    // font-weight: 500;
  }
  // & p{
  //   font-size: 1.8rem;
  //   font-weight: 400;
  //   @media only screen and (max-width: $bp-medium) {
  //     font-size: var(--default-font-size)
  //   }
  // }
}
.clearfix{
  margin-top: 7rem;
  clear: both;
  display: flex;
  justify-content: center;
}
.logos {
  margin-top: 10rem;
  //background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
}
.logo {
  display: flex;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  &:first-child{
    animation: App-logo-spin infinite 20s linear;
  }
}